<template lang="pug">
.w-100
  navs

  .content-height
    .section.brands-bg.text-center
      b-container
        h3.font-weight-bold.text-white.mb-0.text-shadow Aviso de privacidad

    .section
      b-container
        p.text-justify.
          MATELCO MATERIALES ELECTRICOS S.A. DE C.V., con domicilio en 20 de noviembre #41
          A-6 Col. Centro, C.P. 9000 Tlaxcala,Tlaxcala, se encuentra comprometida con la protección de
          sus datos personales, al ser responsable de su uso, manejo, almacenamiento y
          confidencialidad de acuerdo a lo establecido en la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares (en adelante LFPDPPP) y con el fin de darle a
          conocer la información relativa a las características principales del tratamiento al que serán
          sometidos sus Datos Personales, emite el presente Aviso de Privacidad.

        p.text-justify.
          De conformidad a lo dispuesto por la fracción I del artículo 16 de la Ley, MATELCO
          MATERIALES ELECTRICOS S.A. DE C.V será el Responsable de tu información personal
          (Datos Personales).
          Al ingresar al sitio www.ehome.com.mx, autorizas a MATELCO MATERIALES ELECTRICOS
          S.A. DE C.V a utilizar y tratar de forma automatizada tus datos personales e información
          suministrados, los cuales formarán parte de nuestra base de datos con las siguientes
          finalidades que son necesarias para el servicio que solicita: para confirmar su identidad,
          verificar la información que nos proporciona y proveer nuestros servicios y/o productos e
          identificarte.

        p.text-justify.
          De manera adicional, utilizamos su información para notificarle sobre nuevos servicios o
          productos que tengan relación con los ya contratados o adquiridos, así como cambios en los
          mismos; elaborar estudios y programas necesarios para determinar hábitos de consumo,
          realizar evaluaciones periódicas sobre nuestros productos y/o servicios, comunicarte,
          contactarte, enviarte información, actualizar nuestra base de datos, obtener estadísticas y
          testimonios.

        p.text-justify.
          En caso de que no desee que sus datos personales se utilicen para estos fines secundarias,
          envíe un correo a contacto@matelco.mx .

        p.text-justify.
          La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo
          para que le neguemos los servicios y/o productos que solicita o contrata con nosotros. En caso
          de que no manifieste su negativa, se entenderá que autoriza el uso de información personal
          para dichos fines.

        p.text-justify.
          Su información personal, aún la sensible, será utilizada con fines de identificación, operación,
          administración y ejecución de los contratos celebrados con nuestra empresa, asimismo se
          mantendrán en archivos documentales y electrónicos protegidos, bajo resguardo y con acceso
          limitado, siendo la persona responsable de su cuidado Juan Carlos Meléndez.

        p.text-justify.
          El ejercicio de sus derechos de acceso, rectificación, cancelación ó renovación del
          consentimiento, también podrá llevarse a cabo mediante escrito debidamente firmado que
          presente directamente en nuestras oficinas a partir del 25 de diciembre 2020.

        p.text-justify.
          Cualquier modificación al presente aviso, podrá ser consultado a través de nuestro sitio web
          www.matelco.mx .

  footer-component
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import FooterComponent from '@/components/Footer.vue'
import Navs from '@/components/Navs.vue'

export default {
  components: { ContactForm, FooterComponent, Navs },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.brands-bg {
  position: relative;
  width: 100%;
  background-image: url('~@/assets/images/novelties-bg.png');
  background-position: center;
  background-size: cover;
}

.content-height {
  margin-top: 106px;
  min-height: calc(100vh - 106px - 177px);

  @include media-breakpoint-up(md) {
    margin-top: 112px;
    min-height: calc(100vh - 112px - 57.31px);
  }
}
</style>